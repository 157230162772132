import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import io from "socket.io-client";
import { Buffer } from "buffer";
import { connectPro, enterChat, signIn } from "../../store/actions/chatActions";
import { toast } from "react-toastify";
import { LogoUrl, validateEmail } from "../../utils";
import autosize from "autosize";

var socket;
window.Buffer = window.Buffer || Buffer;

function ChatPreview() {
  const inputRef = useRef();
  const [{
    userId,
    chatId,
    name,
    email,
    fileName,
    warrantyUrl,
    stage,
    messageHistory,
    typing,
    connectProVisible,
    socketConnected
  }, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'setName': {
        return {
          ...state,
          stage: 1,
          name: action.value,
          messageHistory: [
            ...state.messageHistory,
            {
              name: action.value,
              message: action.value,
              date: new Date()
            },
            {
              name: 'BOT',
              message: 'Enter your email please =)',
              date: new Date()
            }
          ],
        };
      }
      case 'setEmail': {
        return {
          ...state,
          email: action.value,
          messageHistory: [
            ...state.messageHistory,
            {
              name: state.name,
              message: action.value,
              date: new Date()
            },
            {
              name: 'BOT',
              message: 'This is preview chat. Choose warranty you would be questioning about.',
              date: new Date()
            }
          ],
          stage: 2
        };
      }
      case 'setWarrantyURL': {
        return {
          ...state,
          warrantyUrl: action.value,
        };
      }
      case 'setWarranty': {
        return {
          ...state,
          messageHistory: [
            ...state.messageHistory,
            {
              name: 'BOT',
              message: `File ${state.fileName} is loaded. Welcome to the OpenAi ChatBot! Feel free to ask your questions!`,
              date: new Date()
            }
          ],
          stage: 3,
          connectProVisible: true
        };
      }
      case 'setUserId': {
        return {
          ...state,
          userId: action.value
        };
      }
      case 'setChatId': {
        return {
          ...state,
          chatId: action.value
        };
      }
      case 'setFilename': {
        return {
          ...state,
          fileName: action.value
        };
      }
      case 'setSocketConnected': {
        return {
          ...state,
          socketConnected: action.value
        };
      }
      case 'setAnswer': {
        return {
          ...state,
          answer: action.value,
          messageHistory: [
            ...state.messageHistory,
            {
              name: "BOT",
              message: action.value,
              date: new Date()
            }
          ],
          typing: false,
        };
      }
      case 'setQuestion': {
        return {
          ...state,
          question: action.value,
          messageHistory: [
            ...state.messageHistory,
            {
              name: state.name,
              message: action.value,
              date: new Date()
            }
          ],
          typing: true,
        };
      }
      default: {
        break;
      }
    }
    
  }, {
    userId: "",
    chatId: "",
    name: "",
    email: "",
    question: "",
    warrantyUrl: "",
    fileName: "",
    stage: 0,
    messageHistory: [
      {
        name: 'BOT',
        message: 'Disclaimer: this model is powered by artificial intelligence and is example for preview. You would be able to choose warranty for test performance.',
        date: new Date()
      },
      {
        name: 'BOT',
        message: 'Hi, its OpenAI assistant. What\'s your name?',
        date: new Date()
      }],
    typing: false,
    socketConnected: false,
    connectProVisible: false
  });
  
  useLayoutEffect(() => {
    socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on("answer received", (newMessageReceived) => {
      dispatch({ type: 'setAnswer', value: newMessageReceived });
      setDisabled(false);
      window.scrollTo(0, document.body.scrollHeight);
    });
  }, []);
  
  useEffect(() => {
    autosize(document.querySelectorAll('textarea'))
  }, []);
  
  const [disabled, setDisabled] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  
  const handleNext = async (e) => {
    e.preventDefault();
    switch (stage) {
      case 0: {
        dispatch({ type: 'setName', value: inputRef.current.value });
        inputRef.current.value = "";
        inputRef.current.type = "email";
        break;
      }
      case 1: {
        try {
          const email = inputRef.current.value;
          if (!validateEmail(email)) {
            throw new Error('use valid email.');
          }
          dispatch({ type: 'setEmail', value: email });
          const { data } = await signIn({ name, email });
          dispatch({ type: 'setUserId', value: data.id });
          setShowOptions(true);
          socket.emit("finish sign-in", { userId: data.id });
        } catch (e) {
          console.log('stage 1:', e);
          toast.error('Please, use valid email.');
        }
        break;
      }
      case 2: {
        try {
          dispatch({ type: 'setWarranty' });
          const { data } = await enterChat({ userId, warrantyUrl });
          dispatch({ type: 'setChatId', value: data.chatId });
          
          socket.emit("join chat", { chatId: data.chatId });
          setShowOptions(false);
        } catch (e) {
          console.log('stage 2: ', e);
          toast.error('Please, load only .pdf files less than 1MB.');
        }
        break;
      }
      case 3: {
        try {
          const prompt = inputRef.current.value;
          dispatch({ type: 'setQuestion', value: prompt });
          inputRef.current.value = "";
          socket.emit('ask question', { prompt, userId, chatId, warrantyUrl });
          setDisabled(true);
        } catch (e) {
          console.log('stage 3: ', e);
        }
      }
    }
  };
  
  return (
    <section className="chat">
      <header>
        <div className="chat-heading">
          <div className="chat-heading-sect">
            <img alt="logo" src={LogoUrl}/>
            {connectProVisible && messageHistory.length >= 8 &&
              <button className="chat-pro-btn"
                      onClick={() => connectPro(email)}>Connect PRO</button>
            }
          </div>
        </div>
      </header>
      <main className="chat-messages" id="#scroller">
        {messageHistory.map((item, index) => (
          <div className={`message ${item.name === "BOT" ? "left-msg" : "right-msg"}`} key={`message-${index}`}>
            <div className="message-item">
              <div className="message-info">
                <div className="message-info-name">{item.name}</div>
                <div className="message-info-time">{item.date.toLocaleTimeString()}</div>
              </div>
              <div className="message-text">{item.message}</div>
            </div>
          </div>
        ))}
        {typing &&
          <div className="typed">Typing...</div>
        }
        <div id="#anchor"/>
      </main>
      <form className="chat-inputarea">
        {!showOptions
          ? <textarea className="chat-input" ref={inputRef} placeholder="Enter your message..."
                   id="#bottom"/>
          : <div className="chat-input">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
                     onClick={(e) => {
                       window.open('https://warranty-gpt-bucket.s3.amazonaws.com/warranty+ua.pdf', '_blank');
                       dispatch({
                         type: 'setWarrantyURL',
                         value: 'https://warranty-gpt-bucket.s3.amazonaws.com/warranty+ua.pdf'
                       });
                     }}
              />
              <label className="form-check-label" htmlFor="exampleRadios1">
                Bosch motor for Ukraine in Ukrainian
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"
                     onClick={(e) => {
                       window.open('https://warranty-gpt-bucket.s3.amazonaws.com/warranty+en-hongkong.pdf', '_blank');
                       dispatch({
                         type: 'setWarrantyURL',
                         value: 'https://warranty-gpt-bucket.s3.amazonaws.com/warranty+en-hongkong.pdf'
                       });
                     }}
              />
              <label className="form-check-label" htmlFor="exampleRadios2">
                Bosch motor for Hong-Kong in English
              </label>
            </div>
          </div>
        }
        <button className="chat-send-btn"
                disabled={disabled}
                onClick={(event) => handleNext(event)}
        >
          <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.2417 0.15775L0.609861 13.2144C-0.273928 13.7222 -0.161624 14.9527 0.717283 15.3238L5.90771 17.5015L19.936 5.13822C20.2046 4.89896 20.5854 5.26517 20.356 5.54349L8.59326 19.8745V23.8052C8.59326 24.9576 9.98486 25.4117 10.6685 24.5767L13.769 20.8023L19.853 23.3511C20.5464 23.6441 21.3374 23.2095 21.4644 22.4624L24.98 1.36869C25.146 0.38236 24.0864 -0.330531 23.2417 0.15775Z"
              fill={disabled ? "#eeeeee" : "#2CB9FDFF"}
            />
          </svg>
        </button>
      </form>
    </section>
  );
}

export default ChatPreview;
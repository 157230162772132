import Chat from "../components/chat/Chat";
import ChatPreview from "../components/chatPreview/ChatPreview";

export const AppRoutes = [{
  path: "/chat/preview",
  Component: <ChatPreview/>
}]

export const PrivateRoutes = [{
  path: "/chat",
  Component: <Chat/>
}]
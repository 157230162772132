import React from 'react';
import AppRouter from "./AppRouter";
import {ToastContainer} from "react-toastify";
import {BrowserRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppRouter/>
      </BrowserRouter>
      <ToastContainer/>
    </React.Fragment>
  );
}

export default App;

const S3_BUCKET = String(process.env.REACT_APP_S3_BUCKET);
const REGION = String(process.env.REACT_APP_S3_REGION);
const ACCESS_KEY = String(process.env.REACT_APP_S3_ACCESS_KEY);
const SECRET_ACCESS_KEY = String(process.env.REACT_APP_S3_SECRET_ACCESS_KEY)

export const AWS_CONFIG = (dirName) => {
  return {
    bucketName: S3_BUCKET,
    dirName,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  }
}
import { apiGet } from "../../http/headerPlaceholder.instance";

export const checkToken = async () => {
  try {
    return apiGet({
      url: '/users/check-token',
    });
  } catch (e) {
    console.log('[chatActions.entrerChat]: ', e);
  }
};
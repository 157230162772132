import {apiPost} from "../../http/headerPlaceholder.instance";
import {toast} from "react-toastify";


export const signIn = async ({name, email}) => {
  try {
    return await apiPost({
      url: '/users/sign-in',
      data: {
        name,
        email
      }
    })
  } catch (e) {
    console.log('[chatActions.entrerChat]: ', e)
  }
}

export const enterChat = async ({userId, warrantyUrl}) => {
  try {
    return await apiPost({
      url: '/chats',
      data: {
        userId,
        warrantyUrl
      }
    })
  } catch (e) {
    console.log('[chatActions.entrerChat]: ', e)
  }
}

export const connectPro = async (email) => {
  try {
    const {data} = await apiPost({
      url: '/chats/mailpro',
      data: {
        email
      }
    })
    toast.success('Message with help request was sent to the PRO!')
  } catch (e) {
    toast.error('Some error occured, we are sorry, try again later!')
    console.log('[chatActions.connectPro]: ', e)
  }
}
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppRoutes, PrivateRoutes } from "../utils/routes";
import { checkToken } from "../store/actions/authActions";

const AppRouter = () => {
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const a = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token") || localStorage.getItem("accessToken");
        if (token) {
          localStorage.setItem("accessToken", token);
          const { data } = await checkToken();
          setLogged(data.success);
        } else {
          setLogged(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setLogged(false);
      }
    };
    a();
  }, []);
  
  if (loading) {
    return null;
  }
  return (
    <Routes>
      {logged &&
        PrivateRoutes.map(({ path, Component }) => (
          <Route path={path} element={Component} key={path}/>
        ))}
      {AppRoutes.map(({ path, Component }) => (
        <Route path={path} element={Component} key={path}/>
      ))}
      <Route
        path="*"
        element={<Navigate to="/chat/preview" replace/>}
      />
    </Routes>
  );
};

export default AppRouter;